// // import React, { forwardRef, useImperativeHandle } from "react";
// // import html2canvas from 'html2canvas';
// // import { jsPDF } from 'jspdf';
// // import IconExport from '@mui/icons-material/FileDownload';

// // // Use forwardRef to expose handleExportClick
// // const ExportButton = forwardRef((props, ref) => {
// //     const handleExportClick = async () => {
// //         const element = document.getElementById("export");
// //         if (element) {
// //             try {
// //                 const canvas = await html2canvas(element);
// //                 const image = canvas.toDataURL("image/png");

// //                 const pdf = new jsPDF();
// //                 const imgProps = pdf.getImageProperties(image);
// //                 const pdfWidth = pdf.internal.pageSize.getWidth();
// //                 const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

// //                 pdf.addImage(image, "PNG", 0, 0, pdfWidth, pdfHeight);
// //                 pdf.save("hlv.pdf");
// //             } catch (error) {
// //                 console.error("An error occurred while exporting the PDF:", error);
// //             }
// //         } else {
// //             console.error("Element with id 'export' not found");
// //         }
// //     };

// //     // Expose the handleExportClick method via ref
// //     useImperativeHandle(ref, () => ({
// //         export: handleExportClick
// //     }));

// //     return (
// //         <button
// //             className=""
// //             onClick={handleExportClick}
// //             style={props.style}  // Pass any styles or props as needed
// //         >
// //             <IconExport />
// //         </button>
// //     );
// // });

// // export default ExportButton;


// import React, { forwardRef, useImperativeHandle } from "react";
// import html2canvas from "html2canvas";
// import { jsPDF } from "jspdf";
// import IconExport from "@mui/icons-material/FileDownload";

// // Use forwardRef to expose handleExportClick
// const ExportButton = forwardRef((props, ref) => {
//   const handleExportClick = async () => {
//     const element = document.getElementById("export");
//     if (element) {
//       try {
//         const canvas = await html2canvas(element);
//         const image = canvas.toDataURL("image/png");

//         const pdf = new jsPDF();
//         const imgProps = pdf.getImageProperties(image);
//         const pdfWidth = pdf.internal.pageSize.getWidth();
//         const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

//         // Set the color for the heading
//         pdf.setTextColor(36, 82, 123); // RGB value for #24527B
        
//         // Set font size for heading
//         pdf.setFontSize(18);
        
//         // Define the heading text
//         const heading = "Life Time Report";
        
//         // Calculate the center position for the text
//         const textWidth = pdf.getStringUnitWidth(heading) * pdf.getFontSize() / pdf.internal.scaleFactor;
//         const textX = (pdfWidth - textWidth) / 2; // Centered X position

//         // Set font family (assume 'fangsong' is loaded, using 'helvetica' for demo)
//         pdf.setFont("helvetica", "bold"); // Set font style to bold
        
//         // Add the centered heading
//         pdf.text(heading, textX, 20); // Draw the heading

//         // Add the image 20px below the heading (which is at 20px)
//         const imageYPosition = 40; // 20px margin for heading + 20px extra space
//         pdf.addImage(image, "PNG", 0, imageYPosition, pdfWidth, pdfHeight);

//         // Save the PDF
//         pdf.save("hlv.pdf");
//       } catch (error) {
//         console.error("An error occurred while exporting the PDF:", error);
//       }
//     } else {
//       console.error("Element with id 'export' not found");
//     }
//   };

//   // Expose the handleExportClick method via ref
//   useImperativeHandle(ref, () => ({
//     export: handleExportClick,
//   }));

//   return (
//     <button
//       className=""
//       onClick={handleExportClick}
//       style={props.style} // Pass any styles or props as needed
//     >
//       <IconExport />
//     </button>
//   );
// });

// export default ExportButton;




import React, { forwardRef, useImperativeHandle } from "react";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import IconExport from "@mui/icons-material/FileDownload";

const ExportButton = forwardRef((props, ref) => {
  const handleExportClick = async () => {
    const element = document.getElementById("export");
    if (element) {
      try {
        const canvas = await html2canvas(element);
        const image = canvas.toDataURL("image/png");

        const pdf = new jsPDF();
        const imgProps = pdf.getImageProperties(image);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

        pdf.setTextColor(36, 82, 123); 
        
        pdf.setFontSize(18);
        
        const heading = "Life Time Report";
        
        const textWidth = pdf.getStringUnitWidth(heading) * pdf.getFontSize() / pdf.internal.scaleFactor;
        const textX = (pdfWidth - textWidth) / 2; 

        pdf.setFont("helvetica", "bold"); 
        
        pdf.text(heading, textX, 20); 

        const xOffset = 10; 
        const imageYPosition = 40; 

        pdf.addImage(image, "PNG", xOffset, imageYPosition, pdfWidth - xOffset, pdfHeight);

        pdf.save("hlv.pdf");
      } catch (error) {
        console.error("An error occurred while exporting the PDF:", error);
      }
    } else {
      console.error("Element with id 'export' not found");
    }
  };

  useImperativeHandle(ref, () => ({
    export: handleExportClick,
  }));

  return (
    <button
      className=""
      onClick={handleExportClick}
      style={props.style} 
    >
      <IconExport />
    </button>
  );
});

export default ExportButton;
