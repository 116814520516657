import React, { useState, useEffect } from "react";
import { Box, Grid, TextField, Typography } from "@mui/material";
import Header from "../HeadingList/HeadingList"; // Adjust the path as needed
import "./FormDesign.css";

const ClientInfo = ({ onDataChange, initialData }) => {
  const [formData, setFormData] = useState({
    personal: {
      firstName: "",
      lastName: "",
      birthDay: "",
    },
    spouse: {
      firstName: "",
      lastName: "",
      birthDay: "",
    },
    location: {
      address: "",
      city: "",
      state: "",
      zip: "",
    },
  });

  // Update formData when initialData changes
  useEffect(() => {
    if (initialData) {
      setFormData({
        personal: {
          firstName: initialData.personal?.firstName || "",
          lastName: initialData.personal?.lastName || "",
          birthDay: initialData.personal?.birthDay || "",
        },
        spouse: {
          firstName: initialData.spouse?.firstName || "",
          lastName: initialData.spouse?.lastName || "",
          birthDay: initialData.spouse?.birthDay || "",
        },
        location: {
          address: initialData.location?.address || "",
          city: initialData.location?.city || "",
          state: initialData.location?.state || "",
          zip: initialData.location?.zip || "",
        },
      });
    }
  }, [initialData]);

  // Call onDataChange when formData changes
  useEffect(() => {
    onDataChange(formData);
  }, [formData, onDataChange]);

  // Handle changes in input fields
  const handleChange = (section, field, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [section]: {
        ...prevData[section],
        [field]: value,
      },
    }));
  };

  return (
    <>
      <Header name="CLIENT INFO" className="headerSpace" />
      <Box className="mt-1" sx={{ marginBottom: 6, padding: 2 }}>
        {/* Personal Information */}
        <Grid
          container
          spacing={2}
          className="gridHeight"
          alignItems="center"
          sx={{ mt: 1, flexWrap: "nowrap" }}
        >
          <Grid xs={12} sm={2}>
            <Box
              sx={{ display: "flex", alignItems: "center" }}
              className="newClass"
            >
              <Typography variant="body1" className="list-1">
                Personal
              </Typography>
            </Box>
          </Grid>

          <div className="grid-container">
            <div className="grid-item">
              <input
                type="text"
                onInput={(e) => {
                  // Trim leading spaces
                  let inputValue = e.target.value
                    .trimStart()
                    .replace(/[^A-Za-z\s]/g, "");
                  e.target.value =
                    inputValue.charAt(0).toUpperCase() + inputValue.slice(1);
                }}
                placeholder="First Name"
                value={formData.personal.firstName}
                onChange={(e) =>
                  handleChange("personal", "firstName", e.target.value)
                }
              />
            </div>

            <div className="grid-item">
              <input
                type="text"
                onInput={(e) => {
                  // Trim leading spaces
                  let inputValue = e.target.value
                    .trimStart()
                    .replace(/[^A-Za-z\s]/g, "");
                  e.target.value =
                    inputValue.charAt(0).toUpperCase() + inputValue.slice(1);
                }}
                placeholder="Last Name"
                value={formData.personal.lastName}
                onChange={(e) =>
                  handleChange("personal", "lastName", e.target.value)
                }
              />
            </div>

            <div className="grid-item">
            <input
                type="date"
                max="2019-12-25"
                step="1"
                value={formData.personal.birthDay}
                onChange={(e) =>
                  handleChange("personal", "birthDay", e.target.value)
                }
                style={{
                  width: "100%",
                  padding: "8px",
                  boxSizing: "border-box",
                  cursor: "pointer",
                }}
              />
            </div>
          </div>
        </Grid>

        {/* Location Information */}
        <Grid
          container
          spacing={2}
          className="gridHeight"
          alignItems="center"
          sx={{ mt: 1, flexWrap: "nowrap" }}
        >
          <Grid xs={12} sm={2}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography variant="body1" className="list-1 locResp">
                Location
              </Typography>
            </Box>
          </Grid>

          <div className="grid-container">
            <div className="grid-item address-input">
              <input
                type="text"
                className="address-input"
                onInput={(e) => {
                  // Trim leading spaces
                  let inputValue = e.target.value.trimStart();
                  inputValue = inputValue.replace(
                    /[^A-Za-z0-9\s.,#\-&@()\/\\'";:!?%$*+=<>~`|]/g,
                    ""
                  );
                  if (inputValue) {
                    e.target.value =
                      inputValue.charAt(0).toUpperCase() + inputValue.slice(1);
                  } else {
                    e.target.value = "";
                  }
                }}
                placeholder="Address"
                value={formData.location.address}
                onChange={(e) =>
                  handleChange("location", "address", e.target.value)
                }
              />
            </div>

            <div className="grid-item">
              <input
                type="text"
                onInput={(e) => {
                  // Trim leading spaces
                  let inputValue = e.target.value
                    .trimStart()
                    .replace(/[^A-Za-z\s]/g, "");
                  e.target.value =
                    inputValue.charAt(0).toUpperCase() + inputValue.slice(1);
                }}
                placeholder="City"
                value={formData.location.city}
                onChange={(e) =>
                  handleChange("location", "city", e.target.value)
                }
              />
            </div>

            <div className="grid-item">
              <input
                type="text"
                placeholder="State"
                value={formData.location.state}
                onInput={(e) => {
                  let inputValue = e.target.value.replace(/[^A-Za-z\s]/g, "");
                  e.target.value =
                    inputValue.charAt(0).toUpperCase() + inputValue.slice(1);
                }}
                onChange={(e) =>
                  handleChange("location", "state", e.target.value)
                }
              />
            </div>

            <div className="grid-item">
              <input
                type="text"
                onInput={(e) =>
                  (e.target.value = e.target.value.replace(/[^0-9]/g, ""))
                }
                placeholder="Zip"
                maxLength={6}
                value={formData.location.zip}
                onChange={(e) =>
                  handleChange("location", "zip", e.target.value)
                }
              />
            </div>
          </div>
        </Grid>
      </Box>
    </>
  );
};

export default ClientInfo;
