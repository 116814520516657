import React from "react";
import LeftSidenav from "./LeftSidenav/LeftSidenav";
import { Box, Typography } from "@mui/material";
import RightNav from "./RightNav/RightNav";
import SurplusChart from "./Report/Graph";
import ExportButton from "./Report/ExportButton";
import exportIcon from '../assets/pdfdownload.png';

const ClientDetails = () => {
  const exportRef = React.useRef();

  const handleImageClick = () => {
    // Call the exposed export method from ExportButton
    if (exportRef.current) {
      exportRef.current.export();
    }
  };

  return (
    <Box sx={{ display: "flex", height: "100vh" }}>
      <LeftSidenav />
      <Box component="main" sx={{ flexGrow: 1, p: 3, overflowY: "auto" }}>
        <Typography
          variant="h4"
          component="h1"
          gutterBottom
          sx={{
            textAlign: "center",
            color: "#24527B",
            letterSpacing: "5px",
            fontFamily: "fangsong",
          }}
        >
          LIFE TIME REPORT
          <span>
            <img
              src={exportIcon}
              alt="Export Icon"
              style={{ cursor: "pointer", marginLeft: "10px", height: "20px" }}
              onClick={handleImageClick}
            />
            <ExportButton ref={exportRef} style={{ display: "none" }} />
          </span>
        </Typography>
        <SurplusChart />
      </Box>
      <RightNav />
    </Box>
  );
};

export default ClientDetails;
