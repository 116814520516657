import React, { useState, useEffect, useContext } from "react";
import { Box } from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { useNavigate, useLocation } from "react-router-dom";
import LeftSidenav from "../LeftSidenav/LeftSidenav";
import "./Debt.css";
import api from "../../api/tokenCode";
import ClientContext from "../../context/ClientContext";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { notifySuccess, notifyError } from "../../constant/toast";
// import { fetchGraphData } from "../../slices/lifeTimeReport";
// import { useDispatch } from "react-redux";

const checkboxes = [
  { id: 1, title: "Vehicle" },
  { id: 2, title: "Student Loan" },
  { id: 3, title: "Mortgage" },
  { id: 4, title: "Personal Loan" },
  { id: 5, title: "Credit Card" },
];

const InputField = ({ placeholder, register, error, type = "text", style }) => (
  <div className="input-field-container">
    <input
      type={type}
      className="input-field"
      onInput={(e) => {
        if (type === "text") {
          let inputValue = e.target.value.replace(/[^A-Za-z\s]/g, "");
          e.target.value =
            inputValue.charAt(0).toUpperCase() + inputValue.slice(1);
        } else if (type === "number") {
          e.target.value = e.target.value.replace(/[eE]/g, ""); // Prevent 'e' and 'E'
        }
      }}
      placeholder={placeholder}
      {...register}
      style={style}
    />

    {error && <p className="error-message">{error.message}</p>}
  </div>
);

const Debt = ({ data }) => {
  const {
    register,
    handleSubmit,
    control,
    reset,
    setValue,
    formState: { errors },
  } = useForm();
  const [loanDetails, setLoanDetails] = useState([]);
  const [savedDebt, setSavedDebt] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();
  const { setClient } = useContext(ClientContext);

  const [debtData, setDebtData] = useState(data || location.state?.debtData);
  const clientData = JSON.parse(localStorage.getItem("client"));
  const userId = clientData.user_id;
  // const dispatch = useDispatch()

  useEffect(() => {
    const fetchDebtData = async () => {
      try {
        const response = await api.get(`/debt/getDebtDetails/${userId}`);
        setDebtData(response.data.debtData);
      } catch (error) {
        console.error("Error fetching debt data:", error);
      }
    };

    if (!debtData) {
      fetchDebtData();
    }
  }, [debtData]);

  const handleCheckboxChange = (id) => {
    // Uncheck all other checkboxes
    checkboxes.forEach(({ id: checkboxId }) => {
      if (checkboxId !== id) {
        setValue(`checkboxes.${checkboxId}`, false);
      }
    });
  };

  const handleRowClick = () => {
    navigate(`/details/${clientData.id}`);
  };

  const onSubmit = async (formData) => {
    const selectedType =
      checkboxes.find(({ id }) => formData.checkboxes?.[id])?.title ||
      (formData.other ? "Other" : "");
    const newLoanDetail = {
      name: formData.name || "",
      loanAmount: formData.loanAmount || "",
      apr: formData.apr || "",
      currentPayment: formData.currentPayment || "",
      minimumPayment: formData.minimumPayment || "",
      startDate: formData.startDate || "",
      type: selectedType,
      term: 260,
      otherStr: formData.other ? formData.otherDescription || "None" : "None",
      clientId: userId,
    };

    const updatedLoanDetails = [...loanDetails, newLoanDetail];
    setLoanDetails(updatedLoanDetails);

    try {
      const response = await api.post("/debt/createDebt", {
        loan: updatedLoanDetails,
      });

      setSavedDebt(response.data);
      reset();
      notifySuccess("Debt Created successfully!");
      // dispatch(fetchGraphData(userId));
      setTimeout(() => {
        navigate(`/details/${clientData.id}`);
      }, 2000);
    } catch (error) {
      notifyError(` ${error.response?.data?.message}`);
      console.error("Form submission error:", error);
    }
  };

  return (
    <Box sx={{ display: "flex", height: "100vh" }}>
      <LeftSidenav data={savedDebt} />

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          paddingTop: 5,
          paddingBottom: 5,
          paddingLeft: 10,
          paddingRight: 10,
          overflowY: "auto",
        }}
      >
        <div className="main-container">
          <div>
            <h1 className="debt-name">DEBT</h1>
            <button
              type="button"
              className="back-button-debts"
              onClick={handleRowClick}
            >
              BACK
            </button>
          </div>

          <div className="container">
            <p className="">LOAN</p>
          </div>
          <hr className="hr-tag" />

          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="checkbox-lists">
              {checkboxes.map(({ id, title }) => (
                <label key={id} className="checkbox-item">
                  <input
                    type="checkbox"
                    className="custom-checkbox"
                    {...register(`checkboxes.${id}`)}
                    onChange={() => handleCheckboxChange(id)}
                  />
                  {title}
                </label>
              ))}
              <label className="checkbox-item">
                <input
                  type="checkbox"
                  className="custom-checkbox"
                  {...register("other")}
                />
                Other:
                <Controller
                  name="otherDescription"
                  control={control}
                  render={({ field }) => (
                    <input
                      type="text"
                      placeholder="Describe"
                      className="input-field"
                      {...field}
                    />
                  )}
                />
              </label>
            </div>

            <div className="input-container">
              <InputField
                placeholder="Lender"
                className="lenderFields"
                register={register("name", {
                  required: "* Lender is required",
                })}
                error={errors.name}
              />
              <InputField
                placeholder="Loan Amount"
                type="number" // Numeric input
                register={register("loanAmount", {
                  required: "* Loan Amt is required",
                })}
                error={errors.loanAmount}
              />
              <InputField
                placeholder="APR %"
                type="number" // Numeric input
                register={register("apr", { required: "* APR is required" })}
                error={errors.apr}
              />
              <InputField
                placeholder="Current Pymt"
                type="number" // Numeric input
                register={register("currentPayment", {
                  required: "* Curnt Pymt is required",
                })}
                error={errors.currentPayment}
              />
              <InputField
                placeholder="Min Pymt"
                type="number" // Numeric input
                register={register("minimumPayment", {
                  required: "* Min Pymt is required",
                })}
                error={errors.minimumPayment}
              />

              <InputField
              style={{cursor:"pointer"}}
                placeholder="Start Date"
                register={register("startDate", {
                  required: "* Start Date is required",
                })}
                error={errors.startDate}
                type="date"
              />

              <div>
                <button type="submit" className="add-button">
                  ADD
                </button>
              </div>
            </div>
          </form>
        </div>
      </Box>
      <ToastContainer />
    </Box>
  );
};

export default Debt;
