import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  TextField,
  Typography,
  Checkbox,
  FormControlLabel,
  IconButton,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import "./FormDesign.css";
import Header from "../HeadingList/HeadingList"; // Adjust the path as needed
import DeleteIcon from "@mui/icons-material/Delete";

const FamilyData = ({ onDataChange, initialData }) => {
  const [data, setData] = useState({
    firstName: "",
    lastName: "",
    birthDay: "",
    lifeInsurancePolicy: false,
    children: [{ name: "", age: "", lifeInsurance: false }],
    houseBill: "",
    familyHistory: "",
    livingBenefits: false,
  });

  useEffect(() => {
    if (initialData) {
      setData({
        firstName: initialData.spouse.firstName,
        lastName: initialData.spouse.lastName,
        birthDay: initialData.spouse.birthDay,
        lifeInsurancePolicy: initialData.spouse.lifeInsurancePolicy === true,

        children: initialData.childFamily.map((child) => ({
          name: child.name || "",
          age: child.age || "",
          lifeInsurance: child.lifeInsurancePolicy === true,
        })),
        houseBill: initialData.householdBills || "",
        familyHistory: initialData.parentsOrGrandparents.yesOrNo || "",
        livingBenefits:
          initialData.parentsOrGrandparents.livingBenefits === true,
      });
    }
  }, [initialData]);

  useEffect(() => {
    const payload = {
      spouse: {
        firstName: data.firstName || "N/A",
        lastName: data.lastName || "N/A",
        birthDay: data.birthDay || "",
        lifeInsurancePolicy: data.lifeInsurancePolicy ? true : false,
      },
      childFamily: data.children.map((child) => ({
        name: child.name,
        age: child.age ? parseInt(child.age, 10) : "",
        lifeInsurancePolicy: child.lifeInsurance ? true : false,
      })),
      householdBills: data.houseBill ? parseFloat(data.houseBill) : "",
      parentsOrGrandparents: {
        yesOrNo: data.familyHistory,
        livingBenefits: data.livingBenefits ? true : false,
      },
    };

    onDataChange(payload);
  }, [data, onDataChange]);

  const handleChange = (e, index, category, name) => {
    const { value, type, checked } = e.target;

    // Trim the value if it's a string
    const trimmedValue = typeof value === "string" ? value.trim() : value;

    setData((prevData) => ({
      ...prevData,
      [category]: Array.isArray(prevData[category])
        ? prevData[category].map((item, i) =>
            i === index
              ? {
                  ...item,
                  [name]: type === "checkbox" ? checked : trimmedValue,
                }
              : item
          )
        : type === "checkbox"
        ? checked
        : trimmedValue,
    }));
  };

  const handleCheckboxChange = (e, index, category, name) => {
    const { checked } = e.target;

    setData((prevData) => ({
      ...prevData,
      [category]: prevData[category].map((item, i) =>
        i === index ? { ...item, [name]: checked } : item
      ),
    }));
  };

  const addNewEntry = (type) => {
    setData((prevData) => ({
      ...prevData,
      [type]: [...prevData[type], { name: "", age: "", lifeInsurance: false }],
    }));
  };

  const deleteEntry = (index, type) => {
    setData((prevData) => ({
      ...prevData,
      [type]: prevData[type].filter((_, i) => i !== index),
    }));
  };

  return (
    <>
      <Header name="FAMILY / BACKGROUND" isPriority className="headerSpace" />

      <Box className="mt-1" sx={{ marginBottom: 6, padding: 2 }}>
        {/* Spouse Section */}

        <Grid
          container
          spacing={2}
          className="gridHeight"
          alignItems="center"
          sx={{ mt: 1, flexWrap: "nowrap" }}
        >
          <Grid xs={12} sm={2}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography variant="body1" className="list-1">
                Spouse
              </Typography>
            </Box>
          </Grid>
          <div className="grid-container">
            <div className="grid-item">
              <input
                type="text"
                onInput={(e) => {
                  let inputValue = e.target.value.replace(/[^A-Za-z\s]/g, "");
                  e.target.value =
                    inputValue.charAt(0).toUpperCase() + inputValue.slice(1);
                }}
                placeholder="First Name"
                value={data.firstName}
                onChange={(e) =>
                  handleChange(e, 0, "firstName", e.target.value)
                }
              />
            </div>

            <div className="grid-item">
              <input
                type="text"
                onInput={(e) => {
                  let inputValue = e.target.value.replace(/[^A-Za-z\s]/g, "");
                  e.target.value =
                    inputValue.charAt(0).toUpperCase() + inputValue.slice(1);
                }}
                placeholder="Last Name"
                value={data.lastName}
                onChange={(e) => handleChange(e, 0, "lastName", e.target.value)}
              />
            </div>

            {/* <div className="grid-item">
            <input
              type="date"
              value={data.birthDay}
              onChange={(e) =>
                handleChange(e,0, "birthDay", e.target.value)
              }
              style={{
                width: "100%",
                padding: "8px",
                boxSizing: "border-box",
              }}
            />
          </div> */}

            <div className="grid-item">
              <input
                type="date"
                max="2019-12-25"
                step="1"
                value={data.birthDay}
                onChange={(e) => handleChange(e, 0, "birthDay", e.target.value)}
                style={{
                  width: "100%",
                  padding: "8px",
                  boxSizing: "border-box",
                  cursor: "pointer",
                }}
              />
            </div>

            {/* Add Checkbox */}
            <div className="grid-item checkboxItem-1">
              <FormControlLabel
                sx={{ whiteSpace: "nowrap" }}
                control={
                  <input
                    type="checkbox"
                    checked={data.lifeInsurancePolicy}
                    className="checkboxDesign"
                    onChange={(e) =>
                      handleChange(e, "spouse", "lifeInsurancePolicy")
                    }
                  />
                }
                label="Life Insurance Policy"
              />
            </div>
          </div>
        </Grid>

        {data.children.map((child, index) => (
          <Grid
            container
            spacing={2}
            className="gridHeight"
            alignItems="center"
            sx={{ mt: 1, flexWrap: "nowrap" }}
            key={index}
          >
            <Grid xs={12} sm={2} className="responsiveWidth">
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <IconButton
                  sx={{ padding: "1px", color: "#24527B" }}
                  className="no-hover"
                  onClick={() => addNewEntry("children")}
                >
                  <AddIcon className="icons" />
                </IconButton>
                <Typography variant="body1" className="spouseTypo">
                  Child
                </Typography>
              </Box>
            </Grid>

            <div className="grid-container">
              <div className="grid-item">
                <input
                  type="text"
                  onInput={(e) => {
                    let inputValue = e.target.value.replace(/[^A-Za-z\s]/g, "");
                    e.target.value =
                      inputValue.charAt(0).toUpperCase() + inputValue.slice(1);
                  }}
                  placeholder="Name"
                  value={child.name}
                  onChange={(e) => handleChange(e, index, "children", "name")}
                />
              </div>

              <div className="grid-item">
                <input
                  type="text"
                  maxLength={3}
                  onInput={(e) =>
                    (e.target.value = e.target.value.replace(/[^0-9]/g, ""))
                  }
                  placeholder="Age"
                  value={child.age}
                  onChange={(e) => handleChange(e, index, "children", "age")}
                />
              </div>

              {/* Add Checkbox */}
              <div className="grid-item checkItem">
                <FormControlLabel
                  sx={{ whiteSpace: "nowrap" }}
                  control={
                    <input
                      type="checkbox"
                      checked={child.lifeInsurance}
                      className="checkboxDesign"
                      onChange={(e) =>
                        // handleCheckboxChange(index, 'children', e.target.checked)
                        handleCheckboxChange(
                          e,
                          index,
                          "children",
                          "lifeInsurance"
                        )
                      }
                    />
                  }
                  label="Life Insurance Policy"
                />
                {data.children.length > 1 && (
                  <IconButton
                    color="#EFEFEF"
                    className="iconFix no-hover"
                    onClick={() => deleteEntry(index, "children")}
                  >
                    <DeleteIcon />
                  </IconButton>
                )}
              </div>
            </div>
          </Grid>
        ))}

        {/* House Bill Section */}
        <Grid
          container
          spacing={2}
          alignItems="center"
          className="houseCss"
          sx={{ mt: 1, flexWrap: "nowrap" }}
        >
          <Grid xs={12} md={2}>
            <Typography variant="body1" component="span" className="list">
              Household Bills (total)
            </Typography>
          </Grid>

          <div className="grid-container">
            <div className="grid-item">
              <input
                type="text"
                onInput={(e) =>
                  (e.target.value = e.target.value.replace(/[^0-9]/g, ""))
                }
                placeholder="Amount"
                value={data.houseBill}
                onChange={(e) => handleChange(e, 0, "houseBill", "houseBill")}
              />
            </div>
          </div>
        </Grid>

        {/* Family History Section */}
        <Grid
          container
          spacing={2}
          alignItems="center"
          className="historyCss"
          sx={{ mt: 1, mb: 5, display: "flex", flexWrap: "nowrap" }}
        >
          <Grid xs={12} md={2}>
            <Typography variant="body1" component="span" className="typoAlign">
              Parents or Grandparents History of Cancer, Heart Attack, Stroke,
              or Diabetes?
            </Typography>
          </Grid>
          <div className="grid-container">
            <div className="grid-item">
              <input
                type="text"
                onInput={(e) => {
                  let inputValue = e.target.value.replace(/[^A-Za-z\s]/g, "");
                  e.target.value =
                    inputValue.charAt(0).toUpperCase() + inputValue.slice(1);
                }}
                placeholder="Y/N"
                value={data.familyHistory}
                onChange={(e) => handleChange(e, 0, "familyHistory", "y/n")}
              />
            </div>

            {/* Add Checkbox */}
            <div className="grid-item checkItem-2">
              <FormControlLabel
                sx={{ whiteSpace: "nowrap" }}
                control={
                  <input
                    type="checkbox"
                    className="checkboxDesign"
                    checked={data.livingBenefits}
                    onChange={(e) =>
                      handleChange(e, "familyHistory", "livingBenefits")
                    }
                  />
                }
                label="Living Benefits"
              />
            </div>
          </div>
        </Grid>
      </Box>
    </>
  );
};

export default FamilyData;
