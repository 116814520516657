import React, { useMemo, useEffect, useState } from "react";
import { AreaChart, XAxis, YAxis, Legend, ResponsiveContainer, Area, ReferenceLine, CartesianGrid, Tooltip } from "recharts";
import api from '../../api/tokenCode';
import { useLocation } from "react-router-dom";
import './Graph.css'

const formatInteger = (value) => Math.round(value);

const CreateHlv = ({data}) => {
  const clientData = JSON.parse(localStorage.getItem('client'));

  const userId = clientData.user_id;
  const location = useLocation();

  const retireAge = clientData?.futurePlaning?.areYouGoingToRetire?.age;

  const [debtData, setDebtData] = useState(location.state?.debtData || data );
  const [termData, setTermData] = useState(data || location.state?.termData);
  const [wholeData, setWholeData] = useState(data || location.state?.wholeData);
  const [liabilityData, setLiabilityData] = useState([]);

  const birth_date = new Date(clientData?.clientInfo.personal?.birthDay);
  const current_date = new Date();
  const ageDiffMs = current_date.getTime() - birth_date.getTime();
  const ageDate = new Date(ageDiffMs);
  const age = Math.abs(ageDate.getUTCFullYear() - 1970);

  const start = age;
  const end = 100;
  const len = (end - start) * 100;

  const ticks = useMemo(() => {
    const t = [];
    const step = 5;
    for (let i = start; i <= end; i += step) {
      t.push(i);
    }
    if (end % step !== 0) {
      t.push(end);
    }
    return t;
  }, [start, end]);

  const periods = Array.from(
    { length: len },
    (value, index) => start + (index * 0.01)
  );

  const calculateIncome = () => {
    const activeIncome = clientData?.assestData?.activeIncome[0].annual / 1000 * -1;
    const passiveIncome = clientData?.assestData?.passiveIncome[0].annual / 1000;
    const data = [];
    periods.forEach(element => {
      const activeIncomeElement = (element < 65) ? activeIncome : 0;
      data.push(
        { "age": element, "Active Income": activeIncomeElement, "Passive Income": passiveIncome }
      )
    });
    return data;
  };

  const incomeData = useMemo(calculateIncome, [periods, clientData?.assestData.activeIncome, clientData?.assestData.passiveIncome]);

  const height = "28%";
  const incomeHeight = "8%";

  const formatXAxis = (tickItem) => {
    return Math.round(tickItem).toString();
  };

  const calculateLiabilityTerms = (debts, startYear, endYear) => {

    const birth_date = Date.parse(clientData?.clientInfo.personal?.birthDay);
 
    const monthsInYear = 12;
    const terms = [];
    for (let year = startYear; year < endYear; year++) {
        for (let month = 0; month < monthsInYear; month++) {
            terms.push(year + month / monthsInYear);
        }
    }
    terms.push(endYear);


    const debt_amortizations = [];

    if (debts && Array.isArray(debts)) {  // Check if debts is defined and is an array
        debts.forEach(d => {
            let remaining = d.loanAmount;
            const amortization = [];
            Array.from({ length: d.term }, (x, i) => {
                if (i > 0) {
                    const mif = remaining * (d.apr / 100) / 12;
                    remaining = remaining - (d.currentPayment - mif);
                    if (remaining < 0) {
                        remaining = 0;
                    }
                }
                amortization.push(remaining);
            });

            let start_period = 0;
            let i = 0;
            const start = Date.parse(d.startDate);
            terms.forEach(element => {
                const curent = birth_date + (element * 31557600000);
                if (curent >= start && start_period === 0) {
                    start_period = i;
                }
                i++;
            });
            debt_amortizations.push({ debt: d, amortization: amortization, start: start_period });
        });
    } else {
        console.log("debts is either undefined or not an array");
    } 

    const data = [];
    let i = 0;
    terms.forEach(element => {
        const new_element = { "age": element };
        debt_amortizations.forEach(d => {
            let value = 0;
            if (i >= d.start && i < d.amortization.length + d.start) {
                value = d.amortization[i - d.start] / -1000;
            }
            new_element[d.debt.name] = value;
        });
        data.push(new_element);
        i++;
    });

    // console.log("Final Data:", data);

    return data;

  };


  const calculateLiability = (debts, startYear, endYear,periods) => {
    const termData = calculateLiabilityTerms(debts, startYear, endYear)

    let liability_id = 0;
    const data = []
    for (let i = 0; i < periods.length; i++) {
        let current_liability_period = termData[liability_id]
        const next_liability_period = termData[liability_id + 1]
        if (next_liability_period !== undefined && !(periods[i] >= current_liability_period['age'] && periods[i] < next_liability_period['age'])) {
            liability_id++
            current_liability_period = next_liability_period
        }
        const liability = { ... current_liability_period }
        liability.age = periods[i]
        data.push(liability)

    }
    console.log(data,"ujdhjh");
    return data
  }

  const calculateWholeSlopes = (wholes, birth_date) => {
    // console.log(wholes, birth_date, "Javid");

    const slopeCalculation = (y1, y2, x1, x2) => {
        const rise = y2 - y1;
        const run = x2 - x1;
        const m = rise / run;
        const b = y1 - x1 * rise / run;
        return { m, b };
    };

    if (!Array.isArray(wholes)) {
        console.error("wholes is not an array:", wholes);
        return;
    }

    wholes.forEach(w => {

        if (!w) {
            console.error("wholeLifeCoverage is undefined for an entry in wholes:", w);
            return;
        }

        const start = Date.parse(w.startDate);
        const slopes = {};

        // 0-1
        slopes[1] = slopeCalculation(w['year1'], w['year1'], 0, 1);

        // 1-5
        slopes[5] = slopeCalculation(w['year1'], w['year5'], 1, 5);
        // 5-10
        slopes[10] = slopeCalculation(w['year5'], w['year10'], 5, 10);
        // 10 - 20
        slopes[20] = slopeCalculation(w['year10'], w['year20'], 10, 20);
        // final to 100 years
        const age100 = 100 - ((start - birth_date) / 31557600000);
        slopes['final'] = slopeCalculation(w['year20'], w['year40'], 20, age100);

        w.slopes = slopes;
    });
};

    const calculateCoverage = (termData, wholeData, clientData, periods) => {
      console.log(termData,"termSS");
      console.log(wholeData,"shdgv");
      // console.log(clientData,"shdgv");
      // console.log(periods,"shdgv");

      const birth_date = Date.parse(clientData?.clientInfo.personal?.birthDay);
      const coverage = [];
      const data = []

      // Process whole life coverage data
      if (wholeData && Array.isArray(wholeData)) {
          wholeData.forEach(item => {
              const wc = item.wholeLifeCoverage;
              if (wc) {
                  calculateWholeSlopes([wc], birth_date);
                  coverage.push(wc);
              } else {
                  console.error("wholeLifeCoverage is undefined or null in the item");
              }
          });
      } else {
          console.error("wholeData is undefined, null, or not an array");
      }

      // Process term life coverage data
      if (termData && Array.isArray(termData)) {
          termData.forEach(item => {
              const tc = item.termCoverage;
              if (tc) {
                  coverage.push(tc);
              } else {
                  console.error("termLife is undefined or null in the item");
              }
          });
      } else {
          console.error("termData is undefined, null, or not an array");
      }

      periods.forEach(element => {
        const new_element = { "age": element }

        coverage.forEach(c => {
            const start = Date.parse(c.startDate)

            const curent_date = birth_date + (element * 31557600000)
            let value = 0
            if (c.hasOwnProperty("year1")) {
                const policy_age = element - ((start - birth_date) / 31557600000)

                if (curent_date >= start)
                {
                    if (policy_age >= 0 && policy_age <=1) {
                        const s = c.slopes[1]
                        value = ((policy_age * s["m"] + s["b"]) / 1000)
                    } else if (policy_age <=5) {
                        const s = c.slopes[5]
                        value = ((policy_age * s["m"] + s["b"]) / 1000)
                    } else if (policy_age <=10) {
                        const s = c.slopes[10]
                        value = ((policy_age * s["m"] + s["b"]) / 1000)
                    } else if (policy_age <=20) {
                        const s = c.slopes[20]
                        value = ((policy_age * s["m"] + s["b"]) / 1000)
                    } else {
                        const s = c.slopes['final']
                        value = ((policy_age * s["m"] + s["b"]) / 1000)
                    }
                }

            } else if (c.hasOwnProperty("deathBenefit")) {
                const end = Date.parse(c.endDate)
                if (curent_date <= end && curent_date >= start)
                {
                    value = c.deathBenefit / 1000
                }
            }
            new_element[c.carrier] = value
        });
        data.push(new_element);
    });

    console.log(data,"CT data");
    return data
    };

 
    const defs = [];
    const areas = [];
    let i = 0;

    const colors = [
      "#871a1e",
      "#3c1111",
    ];

    if (liabilityData && liabilityData.length > 0) {
      Object.keys(liabilityData[0]).forEach(k => {
    
        if (k !== "age") {
          const color = colors[i];
          const id = "color_" + color;

          defs.push(
            <linearGradient key={id} id={id} x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor={color} stopOpacity={0.8} />
              <stop offset="95%" stopColor={color} stopOpacity={0.4} />
            </linearGradient>
          );
    
          areas.push(
            <Area key={k} type="monotone" dataKey={k} stroke={color} fillOpacity={1} fill={"url(#" + id + ")"} />
          );
    
          i = i + 1;
        }
      });
    } else {
      console.log("liabilityData is either undefined or empty");
    }


    const dataCt =  calculateCoverage(termData, wholeData, clientData, periods)

    const defss = [];
    const areass = [];
    let j = 0;

    const colorss = [
      "#3d6f94",
      "#1e3a5a",
    ];

    if (dataCt && dataCt.length > 0) {
      Object.keys(dataCt[0]).forEach(k => {
        // console.log(k,"aehwjdh");
    
        if (k !== "age") {
          const color = colorss[j];
          // console.log(color,"colorrr");
          const id = "color_" + color;

          defss.push(
            <linearGradient key={id} id={id} x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor={color} stopOpacity={0.8} />
              <stop offset="95%" stopColor={color} stopOpacity={0.4} />
            </linearGradient>
          );
    
          areass.push(
            <Area key={k} type="monotone" dataKey={k} stroke={color} fillOpacity={1} fill={"url(#" + id + ")"} />
          );
    
          j = j + 1;
        }
      });
    } else {
      console.log("liabilityData is either undefined or empty");
    }

    const calculateSurplus = (coverage, liability, income, periods) => {
      console.log("Coverage:", coverage);
      console.log("Liability:", liability);
      console.log("Income:", income);
      console.log("Periods:", periods);

      const data = []
      for (let i = 0; i < periods.length; i++) {
        // income
        let value = income[i]['Active Income']
        // console.log(value,"sjv");


        for (const [key, val] of Object.entries(coverage[i])) {
          if (key !== "age") {
              value += val
              // console.log(value,"dsjbhcj");
          }
        }

        if (liability[i]) {
          for (const [key, val] of Object.entries(liability[i])) {
            if (key !== "age") {
              value += val;
              // console.log(value,"abc");
            }
          }
        } else {
          console.warn(`Liability is undefined or null at index ${i}`);
        }
        // surplus deficit
        let surplus = 0
        let deficit = 0
        if (value > 0) {
            surplus = value
          // console.log(surplus,"king");
        } else {
            deficit = value
        //   console.log(deficit,"queen");
        }

        data.push({
            "age": periods[i],
            "Surplus": surplus,
            "Deficit": deficit
        })
      }

      console.log(data,"surplus data checking ");

    return data
        
    }

    const income = useMemo(() => calculateIncome(), [clientData, periods]);
    // const coverage = useMemo(() => calculateCoverage(termData, wholeData, clientData, periods), [termData, wholeData, periods]);
    const coverage = useMemo(() => {

      return calculateCoverage(termData, wholeData, clientData, periods);
  }, [termData, wholeData, clientData, periods]);
  
    const surplusData = useMemo(() => calculateSurplus(coverage, liabilityData, income, periods), [coverage, liabilityData, income, periods]);
    

  useEffect(() => {
    const fetchDebtData = async () => {
      try {
        const response = await api.get(`/debt/getAllDebtDetails/${userId}`);
        
        setDebtData(response.data.debtData);
  
        calculateLiabilityTerms(response.data.debtData, 30, 100);
        calculateLiability(response.data.debtData, 30, 100,periods);
        const liability = calculateLiability(response.data.debtData, 30, 100, periods);
        setLiabilityData(liability);
      } catch (error) {
        console.error("Error fetching debt data:", error);
      }
    };
  
    if (userId) {
      fetchDebtData();
    }
  }, [debtData, userId]);

  useEffect(() => {
    const fetchTermData = async () => {
      try {
        const response = await api.get(`/coverage/getAllTermCoverageDetails/${userId}`);
        const TermData = response.data.coverageData;
        console.log("TermData Data:", TermData);

        setTermData(TermData);
      } catch (error) {
        console.error("Error fetching term coverage data:", error);
      }
    };

    if (userId) {
      fetchTermData();
    }
  }, [termData, userId]);

  useEffect(() => {
    const fetchWholeData = async () => {
      try {
        const response = await api.get(`/coverage/getAllWholeLifeCoverageDetails/${userId}`);
        const coverageData = response.data.coverageData;
        console.log("Coverage Data:", coverageData);
  
        setWholeData(coverageData);
      } catch (error) {
        console.error("Error fetching whole life coverage data:", error);
      }
    };

    if (userId) {
      fetchWholeData();
    }
  }, [wholeData, userId]);

  // Call calculateCoverage when both wholeData and termData are available
  useEffect(() => {
    if (wholeData && termData && clientData) {
      const birth_date = Date.parse(clientData?.clientInfo.personal?.birthDay);
      calculateWholeSlopes(wholeData, birth_date);
      calculateCoverage(termData, wholeData, clientData, periods);
    }
  }, [wholeData, termData, clientData]);

  useEffect(() => {
    if (liabilityData.length > 0) {
      // console.log("Final Liability Data:", liabilityData[0]);
    }
  }, [liabilityData]);

  // if (!debtData || debtData.length === 0) {
  //  return <div>Loading...</div>
  // }
  // else{
  //   <div>No Data Found</div>;
  // }

      if (Array.isArray(surplusData) && surplusData > 0) {
        for (let i = 0; i < surplusData.length; i++) {
          surplusData[i].age = Math.round(surplusData[i].age);
        }
      } else {
        console.error('surplusData is not an array or is empty');
      }



  return (
    <div className="flex">
      <div style={{ flex: 1 }}>
        <div id="export" style={{ height: "100vh", display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>

          <ResponsiveContainer
            width="98%"
            height={height}
          >

              <AreaChart
                data={surplusData.map(item =>({...item,age:item.age}))}
                syncId="hlv"
                margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
              >
                <defs>
                  <linearGradient id="colorSurplus" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor="#1A7033" stopOpacity={0.8} />
                    <stop offset="95%" stopColor="#1A7033" stopOpacity={0.4} />
                  </linearGradient>
                  <linearGradient id="colorpDeficit" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor="#B41E24" stopOpacity={0.8} />
                    <stop offset="95%" stopColor="#B41E24" stopOpacity={0.4} />
                  </linearGradient>
                </defs>
                <Area
                  type="monotone"
                  dataKey="Surplus"
                  stroke="#1A7033"
                  fillOpacity={1}
                  fill="url(#colorSurplus)"
                />
                <Area
                  type="monotone"
                  dataKey="Deficit"
                  stroke="#B41E24"
                  fillOpacity={1}
                  fill="url(#colorpDeficit)"
                />
                <XAxis
                  dataKey="age"
                  type="number"
                  domain={[ticks[0], ticks.slice(-1)[0]]}
                  ticks={ticks.map(Math.round)}
                  tick={{ fontSize:14 }}
                />
                <YAxis tickFormatter={formatInteger}
                  tick={{ fontSize:14 }}
               />
              <Legend layout="vertical" align="right" verticalAlign="top" width={150} />
                <Tooltip
                  formatter={(value) => {
                    const formattedValue = Math.round(value).toString();
                    return formattedValue;
                  }}
                  labelFormatter={(label) => {
                    const formattedLabel = Math.round(label).toString();
                    return `Age: ${formattedLabel}`;
                  }}
                />

              <ReferenceLine
                x={retireAge}
                stroke="none"
                label={{
                  position: "bottom",
                  value: '▲', // Unicode for an upward arrow
                  style: { fill: '#c7595b', fontSize: '25px' },
                  dy: -25
                }}
              />
              </AreaChart>
            </ResponsiveContainer>

          <ResponsiveContainer width="98%" height={height}>
            <AreaChart  data={dataCt.map(item => ({ ...item, age: item.age }))} syncId="hlv"
              margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
              <defs>
                {defss}
              </defs>
              <XAxis
                dataKey="age"
                type="number"
                domain={[start, end]}
                tickFormatter={formatXAxis}
                ticks={ticks}
                tick={{ fontSize:14 }}
              />
                <YAxis 
                  tick={{ fontSize:14 }}
                />
              <CartesianGrid strokeDasharray="3 3" />
                  <Tooltip
                  formatter={(value) => {
                    const formattedValue = Math.round(value).toString();
                    return formattedValue;
                  }}
                  labelFormatter={(label) => {
                    const formattedLabel = Math.round(label).toString();
                    return `Age: ${formattedLabel}`;
                  }}
                />
                    {areass}
                <Legend layout="vertical" align="right" verticalAlign="top" width={150} />
            </AreaChart>
          </ResponsiveContainer>

          <ResponsiveContainer width="98%" height={incomeHeight}>
            <AreaChart data={incomeData} syncId="hlv" margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
              <defs>
                <linearGradient id="colorPassiveIncome" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#9ECD95" stopOpacity={0.8} />
                  <stop offset="95%" stopColor="#9ECD95" stopOpacity={0.4} />
                </linearGradient>
              </defs>
              <YAxis 
                  tick={{ fontSize:14 }}
                  ticks={[0, 30]} interval={0} tickFormatter={formatInteger} />
              <XAxis 
                  tick={{ fontSize:14 }}
                  dataKey="age" type="number" domain={[ticks[0], ticks[ticks.length - 1]]} ticks={ticks} tickFormatter={formatInteger} />
              <Legend layout="vertical" align="right" verticalAlign="top" width={150} />
              <Area type="monotone" dataKey="Passive Income" stroke="#9ECD95" fillOpacity={1} fill="url(#colorPassiveIncome)" />
              <ReferenceLine y={0} stroke='#666666' strokeWidth={10} />
              <ReferenceLine
                x={retireAge}
                stroke="none"
                label={{
                  position: "bottom",
                  value: '▲', // Unicode for an upward arrow
                  style: { fill: '#c7595b', fontSize: '25px' },
                  dy: -30
                }}
              />
            </AreaChart>
          </ResponsiveContainer>

          <ResponsiveContainer width="98%" height={incomeHeight}>
            <AreaChart data={incomeData} syncId="hlv" margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
              <defs>
                <linearGradient id="colorActiveIncome" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#9ECD95" stopOpacity={0.8} />
                  <stop offset="95%" stopColor="#9ECD95" stopOpacity={0.4} />
                </linearGradient>
              </defs>
              <YAxis ticks={[0, -100]} interval={0} tickFormatter={formatInteger} 
                  tick={{ fontSize:14 }}
              />
              <XAxis 
                  tick={{ fontSize:14 }}
                  dataKey="age" type="number" domain={[ticks[0], ticks[ticks.length - 1]]} ticks={ticks} tickFormatter={formatInteger} />
              <Legend layout="vertical" align="right" verticalAlign="top" width={150} />
              <Area type="monotone" dataKey="Active Income" stroke="#9ECD95" fillOpacity={1} fill="url(#colorActiveIncome)" />
              <ReferenceLine y={0} stroke='#666666' strokeWidth={10} />
            </AreaChart>
          </ResponsiveContainer>

          <ResponsiveContainer width="98%" height={height}>
            <AreaChart
              data={liabilityData.map((item) => ({ ...item, age: item.age }))}
              syncId="hlv"
              margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
            >
              <defs>{defs}</defs>
              <XAxis
                dataKey="age"
                type="number"
                domain={[start, end]}
                tickFormatter={formatXAxis}
                ticks={ticks}
                tick={{ fontSize: 14 }}
              />
              <YAxis tick={{ fontSize: 14 }} />
              <CartesianGrid strokeDasharray="3 3" />
              <Tooltip
                formatter={(value) => {
                  const formattedValue = Math.round(value).toString();
                  return formattedValue;
                }}
                labelFormatter={(label) => {
                  const formattedLabel = Math.round(label).toString();
                  return `Age: ${formattedLabel}`;
                }}
              />
              {areas}
              
              {/* Add ReferenceLine for retire age */}
              <ReferenceLine
                x={retireAge}
                stroke="none"
                label={{
                  position: "bottom",
                  value: '▲', // Unicode for an upward arrow
                  style: { fill: '#c7595b', fontSize: '25px' },
                  dy: -25
                }}
              />
              
              <Legend layout="vertical" align="right" verticalAlign="top" width={150} />
            </AreaChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
}

export default CreateHlv;
